import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavLink = styled(Link)`
  color: #1946bd;
  font-weight: bold;

  
  // &.active {
  //   color: #1946bd;
  //   font-weight: bold;
  // }

  @media screen and (max-width: 820px) {
    &.active {
      color: #1c4a7d;
    }

  }
`;


