import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';

import Home from './components/pages/Home';
import Pet from './components/pages/Pet';
import Merch from './components/pages/Merch';
// import Akfc from './components/pages/Akfc';
import AKFCInterMeta from './components/pages/AKFC_InterMeta';
import MerchCart from './components/pages/MerchCart';
import MerchPayment from './components/pages/MerchPayment';
import GameCenter from './components/pages/GameCenter';
import Charity from './components/pages/Charity';
import Quiz from './components/pages/Quiz';
import Redeem from './components/pages/Redeem';

import Footer from './components/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>

      <Router>

        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/akc-pets" element={<Pet />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/akfc" element={<AKFCInterMeta />} />
          <Route path="/merch-cart" element={<MerchCart />} />
          <Route path="/merch-payment" element={<MerchPayment />} />
          <Route path="/game-center" element={<GameCenter />} />
          <Route path="/H4Handwashing" element={<Charity />} />
          {/* <Route path="/H4Handwashing/quiz" element={<Quiz />} /> */}
          {/* <Route path="/H4Handwashing/redeem" element={<Redeem />} /> */}
        </Routes>

        <Footer />
        
      </Router>

    </>
  );
}

export default App;
