import '../../App.css';
import Marquee from '../Marquee';
import PetHeroSection from '../PetHeroSection';
import Music from '../Music';

function Pet() {
    return (
      <>
      
        <PetHeroSection />
        <Marquee image="BG-34.png" class="akc-pet" />
        <Music />
      </>
    )
  }
  
  export default Pet;