import React, { Component } from 'react';
import { useSearchParams, Link, useHistory } from "react-router-dom";
import { useState } from 'react';
import './MerchCartPayment.css';
import './MerchCartItem.css';
import LoadingModal from './LoadingModal';
import { Form } from 'react-bootstrap';

class MerchCartPayment extends Component {
    constructor() {
        super();
        this.state = {
            cartObject: [],
            countryObject: [],
            country: '',
            fullname: '',
            phoneno: '',
            postalcode: '',
            address: '',
            deliveryFee: '0',
            showModal: false,
            usdtUrl: '',
            merchantId: '',
            chainTypeId: '',
            channelName: '',
            orderAmount: '',
            merchantOrderNo: '',
            notifyUrl: '',
            returnUrl: '',
            sign: '',
        };
        this.getCarts = this.getCarts.bind(this);
        this.getCountries = this.getCountries.bind(this);
    }

    componentDidMount() {
        this.getCarts();
        this.getCountries();

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if( params.get('status') == 'success' && params.get('token') ) {
            this.capturePayment( params.get('token') );
        }
    }

    async capturePayment( token_ ) {

        let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify( { 'token': token_ } )
        };

        const response = await fetch('https://akc-market.com/api/v1/orders/payment', requestOptions).then( response => {
            if( response.status == 200 ) {
                window.location.href="/merch?tab=history"
            } else {
                let errorMessage = '';
                response.json().then( res => {

                    for (const item of Object.keys(res.errors)) {
                        errorMessage += ( res.errors[item] + '\n' );
                    }
                    alert( errorMessage ); 
                } )
                
            }
        } );

        localStorage.setItem( 'akc_cart', [] );
    }

    getCarts() {
    
        let data = {};
        data.cart = [];
        data.cartAmount = 0;
        let localStorageCart = localStorage.getItem( 'akc_cart' );
        if( localStorageCart == undefined || typeof localStorageCart !== 'string' || localStorageCart == '' ) {
            return data;
        }
    
        let cart = JSON.parse( localStorage.getItem( 'akc_cart' ) );
        if( cart.length != 0 ) {
            cart.forEach(element => {
                data.cartAmount+=(parseFloat(element.amount)*element.quantity);
            });
            data.cart = cart;
        }
    
        this.state.cartObject = data;
        this.setState( { cartObject: data } );
        return data;
    }

    async getCountries() {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };

        const response = await fetch('https://akc-market.com/api/v1/countries', requestOptions);
        const json = await response.json().then( data => {
            this.setState( { countryObject: data } );
        } );
    }

    selectCountry( country ) {
        let fee = 0;
        this.setState( { country: country } );
        switch ( country ) {
            case 'CN':
                fee = 6;
                break;
            case 'HK':
            case 'MO':
            case 'TW':
                fee = 8;
                break;
            default:
                fee = 10;
                break;
        }

        return fee;
    }

    async checkOut( paymentMethod ) {

        this.setState( { showModal: true } );
        const order = {
            'order': {
                'fullname': this.state.fullname,
                'phoneno': this.state.phoneno,
                'country': this.state.country,
                'payment_method': paymentMethod,
                'line_items': this.state.cartObject.cart,
                'address_detail': {
                    'name': this.state.fullname,
                    'phone_number': this.state.phoneno,
                    'detail': this.state.address,
                    'postcode': this.state.postalcode,
                },
                'postcode': this.state.postalcode,
            }
        };

        let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify( order )
        };
        const response = await fetch('https://akc-market.com/api/v1/orders', requestOptions).then( response => {
            if( response.status == 200 ) {
                response.json().then( res => {
                    if( paymentMethod == 'paypal' ) {
                        window.location.href = res.payment_gateway
                    } else {
                        this.setState( { 
                            usdtUrl: res.payment_gateway.url,
                            merchantId: res.payment_gateway.merchantId,
                            chainTypeId: res.payment_gateway.chainTypeId,
                            channelName: res.payment_gateway.channelName,
                            orderAmount: res.payment_gateway.orderAmount,
                            merchantOrderNo: res.payment_gateway.merchantOrderNo,
                            notifyUrl: res.payment_gateway.notifyUrl,
                            returnUrl: res.payment_gateway.returnUrl,
                            sign: res.payment_gateway.sign,
                        } );
                        document.getElementById( 'usdt_form' ).submit();
                    }
                } );
            } else {
                // if( showModal == true )
                // setShowModal(false);
                this.setState( { showModal: false } );
                let errorMessage = '';
                response.json().then( res => {

                    for (const item of Object.keys(res.errors)) {
                        errorMessage += ( res.errors[item] + '\n' );
                    }
                    alert( errorMessage ); 
                } )
                
            }
        } );
    }

    render() {

        const paypal_icon = require('../images/BG-47.png');
        const usdt_icon = require('../images/BG-48.png');

        return (
            <div className='wrap-merch-payment'>
                <div className='merchcart-header-container'>
                    <div className='merchcart-header'>
                        <h2 className='merchcart-header-member'>Merch</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='merchcart-header-member'>My Cart</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='merchcart-header-merch'>Purchase</h2>
                    </div>
                
                    <div className='close-merchcart'>
                        <Link to='/merch' className='close-cart-link'><i className="bi bi-x"></i></Link>
                        {/* <a href='/merch' className='close-cart-link'><i className="bi bi-x"></i></a> */}
                    </div>
                </div>

                <div className='merch-payment-container'>
                    <div className='merch-payment-content'>
                    {
                        this.state.cartObject.cart && this.state.cartObject.cart.length == 0 ? <div className="text-center"><br/>No Cart Item</div> : ''
                    }
                    {
                        this.state.cartObject.cart && this.state.cartObject.cart.map( data => {
                            return (
                                <div className="merch-payment-list">
                                    <img src={data.thumbnail} className='merch-payment-item-img' />
                                    <div className='merch-payment-item-desc'>
                                        <h4 className='merch-payment-item-name'>{data.title}</h4>
                                        <p className='merch-payment-item-price'>$ {data.amount}</p>
                                    </div>
                                    <p className='merch-payment-quantity'>x{data.quantity}</p>
                                </div>
                            )
                        } )
                    }
                    </div>

                    <div className='merch-payment-details'>
                        <h3 className='merch-delivery-header'>Delivery Details</h3>
                        <div className='merch-delivery-container'>
                            <div className='merch-del-details'>
                                <div className='merch-del-details-form'>
                                    <p className='merch-del-details-text'>Country or Region</p>
                                        <Form.Select aria-label="Default select example" onChange={e=>{ this.setState( { deliveryFee: this.selectCountry(e.target.value) } );}} >
                                        <option id='form-select' >Select Country or Region</option>
                                        {
                                            this.state.countryObject && this.state.countryObject.map( data => {
                                                return (
                                                    <option id='form-select' value={data.iso_alpha2_code}>{data.country_name}</option>
                                                )
                                            } )
                                        }
                                        {/* <option id='form-select' value="china">China</option>
                                        <option id='form-select' value="hongkong">Hong Kong</option>
                                        <option id='form-select' value="macau">Macau</option>
                                        <option id='form-select' value="taiwan">Taiwan</option>
                                        <option id='form-select' value="other">Other Country</option> */}
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='merch-del-details'>
                                <div className='merch-del-details-form'>
                                    <p className='merch-del-details-text'>Full Name </p>
                                    <input type="text" onChange={(e) => this.setState( { fullname: e.target.value } )} placeholder="Type Full Name "/>
                                </div>
                            </div>
                            <div className='merch-del-details'>
                                <div className='merch-del-details-form'>
                                    <p className='merch-del-details-text'>Phone No. </p>
                                    <input type="text" onChange={(e) => this.setState( { phoneno: e.target.value } )} placeholder="Type Phone No. "/>
                                </div>
                            </div>
                            <div className='merch-del-details'>
                                <div className='merch-del-details-form'>
                                    <p className='merch-del-details-text'>Postal Code </p>
                                    <input type="text" onChange={(e) => this.setState( { postalcode: e.target.value } )} placeholder="Type Postal Code "/>
                                </div>
                            </div>
                            <div className='merch-del-details'>
                                <div className='merch-del-details-form'>
                                    <p className='merch-del-details-text'>Detailed Address </p>
                                    <input type="text" onChange={(e) => this.setState( { address: e.target.value } )} placeholder="Type Detailed Address "/>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                {/* <div className='merch-payment-container'>
                        <div className='merch-payment-details'>
                            <h3 className='merch-delivery-header'>Shipping Method</h3>
                        </div>

                        <div className='merch-shipping-method'>
                            <button className='merch-ship-button' id='ship-metd-btn'>
                                <div className='merch-ship-method-1'>
                                    <div className='ship-method-row-1'>
                                        <span aria-hidden="true" data-icon="&#xe902;" className='shipping-icon-1'></span>
                                        <p className='ship-method-row-1-1'>Air Transport</p>
                                        <p className='ship-method-row-1-2'>Estimate 2 Weeks Working Days</p>
                                    </div>
                                    <div className='ship-method-row-2'>
                                        <span aria-hidden="true" data-icon="&#xe901;" className='shipping-icon-2'></span>
                                        <p className='ship-method-row-1-3'>Ship to Malaysia</p>
                                        <p className='ship-method-row-1-4'>USD 16.99</p>
                                    </div>
                                </div>
                            </button>

                            <button className='merch-ship-button' id='ship-metd-btn'>
                                <div className='merch-ship-method-1'>
                                    <div className='ship-method-row-1'>
                                        <span aria-hidden="true" data-icon="&#xe900;" className='shipping-icon-1'></span>
                                        <p className='ship-method-row-1-1'>Sea Shipping</p>
                                        <p className='ship-method-row-1-2'>Estimate 4 Weeks Working Days</p>
                                    </div>
                                    <div className='ship-method-row-2'>
                                        <span aria-hidden="true" data-icon="&#xe901;" className='shipping-icon-2'></span>
                                        <p className='ship-method-row-1-3'>Ship to Malaysia</p>
                                        <p className='ship-method-row-1-4'>USD 8.99</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div> */}
                <div className='merch-payment-summary'>
                    <div className='merch-payment-method'>
                        <div className='m-p-overall-sum'>
                            <p className='merch-payment-sum-text'>Total Price</p>
                            <p className='merch-payment-sum-price'>$ {parseFloat(this.state.cartObject.cartAmount).toFixed(2)}</p>
                        </div>
                        <div className='m-p-overall-sum'>
                            <p className='merch-payment-sum-text'>Delivery Fee</p>
                            <p className='merch-payment-sum-price'>$ {parseFloat(this.state.deliveryFee).toFixed(2)}</p>
                        </div>
                        <div className='m-p-overall-sum'>
                            <p className='merch-payment-sum-text'>Total Payment</p>
                            <p className='merch-payment-sum-price'>$ {(parseFloat(this.state.cartObject.cartAmount) + parseFloat(this.state.deliveryFee)).toFixed(2)}</p>
                        </div>
                    </div>

                    <div className='merch-payment-final-purc'>
                        <p>Payment Method</p>
                        <div className='payment-method-op'>
                            {/* <button className='p-meth-paypal' onClick={()=>this.checkOut('paypal')}>
                                Paypal <img src={paypal_icon} ></img>
                            </button> */}
                            <button className='p-meth-usdt' onClick={()=>this.checkOut('usdt')}>
                                USDT (ERC20/TRC20) <img src={usdt_icon} ></img>
                            </button>
                        </div>
                    </div>
                </div>
                <LoadingModal show={this.state.showModal}/>
                <form action={this.state.usdtUrl} method='POST' id="usdt_form">
                    <input type='hidden' name='merchantId' value={this.state.merchantId} />
                    <input type='hidden' name='chainTypeId' value={this.state.chainTypeId} />
                    <input type='hidden' name='channelName' value={this.state.channelName} />
                    <input type='hidden' name='orderAmount' value={this.state.orderAmount} />
                    <input type='hidden' name='merchantOrderNo' value={this.state.merchantOrderNo} />
                    <input type='hidden' name='notifyUrl' value={this.state.notifyUrl} />
                    <input type='hidden' name='returnUrl' value={this.state.returnUrl} />
                    <input type='hidden' name='sign' value={this.state.sign} />
                </form>
            </div>
        )
    }
}

// function MerchCartPayment1() {

//     const [showModal, setShowModal] = useState(false);

//     const [searchParams, setSearchParams] = useSearchParams();
//     let paymentStatus = searchParams.get( 'status' );
//     if( paymentStatus == 'success' ) {

//         if( showModal == false )
//             setShowModal(true);

//         let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

//         const requestOptions = {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
//             body: JSON.stringify( { 'token': searchParams.get( 'token' ) } )
//         };

//         const response = fetch('https://akc-market.com/api/v1/orders/payment', requestOptions).then( response => {
//             if( response.status == 200 ) {
//                 window.location.herf="/merch?tab=history"
//             } else {
//                 let errorMessage = '';
//                 response.json().then( res => {

//                     for (const item of Object.keys(res.errors)) {
//                         errorMessage += ( res.errors[item] + '\n' );
//                     }
//                     alert( errorMessage ); 
//                 } )
                
//             }
//         } );

//         localStorage.setItem( 'akc_cart', [] );
//     } 

//     const cartObject = getCarts();

//     const cart_item = require('../images/BG-29.jpg');
//     const paypal_icon = require('../images/BG-47.png');
//     const usdt_icon = require('../images/BG-48.png');

//     // Delivery Details
//     const [country, setCountry] = useState("");
//     const [fullname, setFullName] = useState("");
//     const [phoneno, setPhoneNo] = useState("");
//     const [postalcode, setPostalCode] = useState("");
//     const [address, setAddress] = useState("");

//     const [deliveryFee, setDeliveryFee] = useState(6);
//     const [totalPayment, setTotalPayment] = useState( cartObject.cartAmount + 6 );

//     function selectCountry( country ) {
//         let fee = 0;
//         setCountry(country);
//         switch ( country ) {
//             case 'china':
//                 fee = 6;
//                 break;
//             case 'hongkong':
//             case 'macau':
//             case 'taiwan':
//                 fee = 8;
//                 break;
//             case 'other':
//                 fee = 10;
//             default:
//                 fee = 10;
//                 break;
//         }

//         return fee;
//     }

//     async function checkOut( paymentMethod ) {

//         if( showModal == false )
//             setShowModal(true);

//         const order = {
//             'order': {
//                 'fullname': fullname,
//                 'phoneno': phoneno,
//                 'country': country,
//                 'payment_method': paymentMethod,
//                 'line_items': cartObject.cart,
//                 'address_detail': {
//                     'name': fullname,
//                     'phone_number': phoneno,
//                     'detail': address,
//                     'postcode': postalcode,
//                 },
//                 'postcode': postalcode,
//             }
//         };

//         let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

//         const requestOptions = {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
//             body: JSON.stringify( order )
//         };
//         const response = await fetch('https://akc-market.com/api/v1/orders', requestOptions).then( response => {
//             if( response.status == 200 ) {
//                 response.json().then( res => {

//                     window.location.href = res.payment_gateway
//                 } );
//             } else {
//                 // if( showModal == true )
//                 setShowModal(false);
//                 let errorMessage = '';
//                 response.json().then( res => {

//                     for (const item of Object.keys(res.errors)) {
//                         errorMessage += ( res.errors[item] + '\n' );
//                     }
//                     alert( errorMessage ); 
//                 } )
                
//             }
//         } );
//     }

//     return (
        
//         <div className='wrap-merch-payment'>

//             <div className='merchcart-header-container'>
//                 <div className='merchcart-header'>
//                     <h2 className='merchcart-header-member'>Merch</h2>
//                     <i className="fas fa-solid fa-angle-left" />
//                     <h2 className='merchcart-header-member'>My Cart</h2>
//                     <i className="fas fa-solid fa-angle-left" />
//                     <h2 className='merchcart-header-merch'>Purchase</h2>
//                 </div>
                
//                 <div className='close-merchcart'>
//                     <Link to='/merch' className='close-cart-link'><i className="bi bi-x"></i></Link>
//                 </div>
//             </div>


//             <div className='merch-payment-container'>

//                 <div className='merch-payment-content'>
//                     {
//                         cartObject.cart.length == 0 ? <div className="text-center"><br/>No Cart Item</div> : ''
//                     }
//                     {
//                         cartObject.cart.map( data => {
//                             return (
//                                 <div className="merch-payment-list">
//                                     <img src={data.thumbnail} className='merch-payment-item-img' />
//                                     <div className='merch-payment-item-desc'>
//                                         <h4 className='merch-payment-item-name'>{data.title}</h4>
//                                         <p className='merch-payment-item-price'>$ {data.amount}</p>
//                                     </div>
//                                     <p className='merch-payment-quantity'>x{data.quantity}</p>
//                                 </div>
//                             )
//                         } )
//                     }
//                 </div>

//                 <div className='merch-payment-details'>
//                     <h3 className='merch-delivery-header'>Delivery Details</h3>
//                     <div className='merch-delivery-container'>
//                         <div className='merch-del-details'>
//                             <div className='merch-del-details-form'>
//                                 <p className='merch-del-details-text'>Country </p>
//                                 <Form.Select aria-label="Default select example" onChange={e=>{setDeliveryFee(selectCountry(e.target.value));setTotalPayment(cartObject.cartAmount+selectCountry(e.target.value))}} >
//                                     <option id='form-select' >Select Country</option>
//                                     <option id='form-select' value="china">China</option>
//                                     <option id='form-select' value="hongkong">Hong Kong</option>
//                                     <option id='form-select' value="macau">Macau</option>
//                                     <option id='form-select' value="taiwan">Taiwan</option>
//                                     <option id='form-select' value="other">Other Country</option>
//                                 </Form.Select>
//                             </div>
//                         </div>
//                         <div className='merch-del-details'>
//                             <div className='merch-del-details-form'>
//                                 <p className='merch-del-details-text'>Full Name </p>
//                                 <input type="text" value={fullname} onChange={(e) => setFullName(e.target.value)} placeholder="Type Full Name "/>
//                             </div>
//                         </div>
//                         <div className='merch-del-details'>
//                             <div className='merch-del-details-form'>
//                                 <p className='merch-del-details-text'>Phone No. </p>
//                                 <input type="text" value={phoneno} onChange={(e) => setPhoneNo(e.target.value)} placeholder="Type Phone No. "/>
//                             </div>
//                         </div>
//                         <div className='merch-del-details'>
//                             <div className='merch-del-details-form'>
//                                 <p className='merch-del-details-text'>Postal Code </p>
//                                 <input type="text" value={postalcode} onChange={(e) => setPostalCode(e.target.value)} placeholder="Type Postal Code "/>
//                             </div>
//                         </div>
//                         <div className='merch-del-details'>
//                             <div className='merch-del-details-form'>
//                                 <p className='merch-del-details-text'>Detailed Address </p>
//                                 <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Type Detailed Address "/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Shipping Method */}
//             {/* <div className='merch-payment-container'>
//                 <div className='merch-payment-details'>
//                     <h3 className='merch-delivery-header'>Shipping Method</h3>
//                 </div>

//                 <div className='merch-shipping-method'>
//                     <button className='merch-ship-button' id='ship-metd-btn'>
//                         <div className='merch-ship-method-1'>
//                             <div className='ship-method-row-1'>
//                                 <span aria-hidden="true" data-icon="&#xe902;" className='shipping-icon-1'></span>
//                                 <p className='ship-method-row-1-1'>Air Transport</p>
//                                 <p className='ship-method-row-1-2'>Estimate 2 Weeks Working Days</p>
//                             </div>
//                             <div className='ship-method-row-2'>
//                                 <span aria-hidden="true" data-icon="&#xe901;" className='shipping-icon-2'></span>
//                                 <p className='ship-method-row-1-3'>Ship to Malaysia</p>
//                                 <p className='ship-method-row-1-4'>USD 16.99</p>
//                             </div>
//                         </div>
//                     </button>

//                     <button className='merch-ship-button' id='ship-metd-btn'>
//                         <div className='merch-ship-method-1'>
//                             <div className='ship-method-row-1'>
//                                 <span aria-hidden="true" data-icon="&#xe900;" className='shipping-icon-1'></span>
//                                 <p className='ship-method-row-1-1'>Sea Shipping</p>
//                                 <p className='ship-method-row-1-2'>Estimate 4 Weeks Working Days</p>
//                             </div>
//                             <div className='ship-method-row-2'>
//                                 <span aria-hidden="true" data-icon="&#xe901;" className='shipping-icon-2'></span>
//                                 <p className='ship-method-row-1-3'>Ship to Malaysia</p>
//                                 <p className='ship-method-row-1-4'>USD 8.99</p>
//                             </div>
//                         </div>
//                     </button>
//                 </div>
//             </div> */}

//             <div className='merch-payment-summary'>
//                 <div className='merch-payment-method'>
//                     <div className='m-p-overall-sum'>
//                         <p className='merch-payment-sum-text'>Total Price</p>
//                         <p className='merch-payment-sum-price'>$ {cartObject.cartAmount.toFixed(2)}</p>
//                     </div>
//                     <div className='m-p-overall-sum'>
//                         <p className='merch-payment-sum-text'>Delivery Fee</p>
//                         <p className='merch-payment-sum-price'>$ {deliveryFee.toFixed(2)}</p>
//                     </div>
//                     <div className='m-p-overall-sum'>
//                         <p className='merch-payment-sum-text'>Total Payment</p>
//                         <p className='merch-payment-sum-price'>$ {totalPayment.toFixed(2)}</p>
//                     </div>
//                 </div>

//                 <div className='merch-payment-final-purc'>
//                     <p>Payment Method</p>
//                     <div className='payment-method-op'>
//                         <button className='p-meth-paypal' onClick={()=>checkOut('paypal')}>
//                             Paypal <img src={paypal_icon} ></img>
//                         </button>
//                         <button className='p-meth-usdt' onClick={()=>checkOut('usdt')}>
//                             USDT <img src={usdt_icon} ></img>
//                         </button>
//                     </div>
//                 </div>
//             </div>
//             <LoadingModal show={showModal}/>
//         </div>
//     );
// }

export default MerchCartPayment;





