import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Storyline from '../Storyline';
import Marquee from '../Marquee';
import Roadmap from '../Roadmap';
import Faq from '../FAQ';
import Team from '../Team';

function Home() {
    return (
        <>
            <HeroSection />
            <Storyline />
            <Marquee image="akc_marquee.png" class="homepage" />
            <Roadmap />
            <Faq />
            <Team />
        </>
    );
}

export default Home;