import '../../App.css';
import Charity_Page_Items from '../Charity_Page_Items';
import Marquee from '../Marquee';
import Music from '../Music';

function Charity() {
    return (
      <>
        <Charity_Page_Items />
      </>
    )
  }
  
  export default Charity;