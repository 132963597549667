export const Data = [
    {
      question: "What is Ape Kids Club?",
      answer: "Ape Kids Club is a collection of 9,999 Kids Apes that were sprouted from our thousand year old magical tree. Victorior, the beloved and talented art teacher, will take care of all Kid Apes in the our Club with many planned activities and surprises. Please see roadmap section here."
    },
    {
      question: "Who is the artist behind Ape Kids Club?",
      answer: "Kun Vic, also known as Victorior, is a Thailand based artist who has been a digital artist for nearly half his life since 2009. He has previously worked for EmberLab studio for Coca-Cola (USA), KFC, Bridge of Spirits (Sony Playstation game) , PSYOP for Disney's game, Clash and Clanes, Clash of Royales, Chick-Fil-A , Supercell, among other prestigious brands to create and animate different art concepts. Click here to explore Vic's personal website. "    
    },
    {
    question: "What are the benefits of holding Ape Kids Club? ",      
    answer: "Your Kid Ape gives you access and membership to our discord community in which you will be able to be part of the Back to School Channel, the Lemonade Stand, the Dance Party, among other planned surprises in our roadmap."
    },
    {
    question: "How unique will the Ape Kids Club be? ",      
    answer: "In total 309 traits will exist, with some being more scarce than others. "
    },
    {
    question: "What is the mint price for Ape Kids Club? ",      
    answer: "Pre-sale mint cost is 0.04 ETH + gas. Public mint cost is 0.05 ETH + gas. OG kids holders will receive 1 free mint + gas and 1 whitelist. "
    },
    {
    question: "When is the pre-sale and when is the public sale? ",      
    answer: "Pre-sale starts on November 30th at 8 pm EST - December 1st 1 am UTC - December 1st 9 am GMT +8 Public sale starts on December 1st at 8 pm EST - December 2nd 1 am UTC - December 2nd 9 am GMT +8 "
    },
    {
    question: "I own OG Ape Kids Club, are there any special benefits? ",      
    answer: "You will be able to mint your new Ape Kids Club nft @ 0.04 ETH + gas. "
    }

];