

export const MenuItems = [
    {
      title: 'AKC Pets',
      path: '/akc-pets',
      cName: 'dropdown-link'
    },
    {
      title: 'Merch',
      path: '/merch',
      cName: 'dropdown-link'
    },
    {
      title: 'AKFC',
      path: '/akfc',
      cName: 'dropdown-link'
    },
    {
      title: 'H4Handwashing',
      path: '/H4Handwashing',
      cName: 'dropdown-link'
    },
    {
      title: 'Game Center',
      path: '/game-center',
      cName: 'dropdown-link'
    },
    {
      title: 'Hold to Earn',
      path: '/#',
      cName: 'dropdown-link-coming'
    },
    {
      title: '...',
      path: '/',
      cName: 'dropdown-link-coming'
    }
  ];