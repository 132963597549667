import React from 'react';
import './Storyline.css';
import { Link } from 'react-router-dom';


function Storyline() {

    const prestory_bg = require('../images/BG-02.png');
    const storyline_1 = require('../images/BG-04.png');
    const storyline_2 = require('../images/storyline.png');

    return (
        
        <div className='wrap-storyline'>
            <div className='pre-story'></div>
            <img className='pre-story-desktop' src={prestory_bg} alt='Pre-Story' />
        
            <div id='storyline' className='storyline-maincontainer'>
                <div className='storyline-container'>
                    <div className='monkey'>
                        <img className='lazy' src={storyline_1} alt='popup Ape' />
                    </div>
                    
                    <h1 className='storyline-heading'>Storyline</h1>
                    <div className='storyline-content'>
                        <div className='image-slider'>
                            <img className='lazy' src={storyline_2} alt='Ape Kids Slider' />
                        </div>

                        <div className='storyline-desc'>
                            <p>In a magical world where apes ruled the metaverse, a magical thousand-year-old tree sprouted cute baby apes. A flock of storks were the loyal companions of the thousand-year-old tree. One sunny day, a total of 9999 baby apes were born. Storks followed their inner call and delivered all the baby apes. The families were so grateful and elated that they wanted even more baby apes. All were delivered and all lived a joyous life with their parents.</p>
                        </div>
                        
                        <div className='storyline-desc'>
                            <p >As the parents were busy navigating the metaverse and the kid apes grew up, they left their kid apes with Victorior, the friendliest teacher in the whole metaverse. Victorior was beloved by the kid apes since he is a talented art teacher who spreads his love through art.</p>
                        </div>

                        <div className='storyline-linkbtn'>
                            <a href='https://linktr.ee/Apekidsclub' className='storyline-linktree-btn' target='_blank' rel='noopener'>
                                APE KIDS CLUB LINKTREE
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Storyline;