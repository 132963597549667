import '../../App.css';
import Marquee from '../Marquee';
import GameCenterHeroSection from '../GameCenterHeroSection';
import Music from '../Music';

function GameCenter() {
    return (
      <>
        <GameCenterHeroSection />
      </>
    )
  }
  
  export default GameCenter;