import './PetHeroSection.css';

function PetHeroSection() {

    const akc_pet = require('../images/BG-25.png');

    return (
        <div>
            <div className='wrap-pet-hero'>
                <div className='pet-header-container'>
                    <div className='pet-header'>
                        <h2 className='pet-header-member'>Member</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='pet-header-text'>AKC Pet</h2>
                    </div>
                </div>
            </div>
            
            <div className='header-title'>
                <img id="logo" src={akc_pet} alt="logo"></img>
                <div className="title">AKC PETS</div>
                <a className='hero-btn-item-1' href='https://linktr.ee/Apekidsclub' target='_blank' rel='noreferrer'>
                    APE KIDS CLUB LINKTREE
                </a>
            </div>
        </div>
    );
}

export default PetHeroSection;