import React, { Component } from 'react';

let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';
const paypal_image = require( '../images/BG-47.png' );
const usdt_image = require( '../images/BG-48.png' );

class MerchHistory extends Component {
    constructor() {
        super();
        this.state = {
            orders: [],
        };
        this.getOrders = this.getOrders.bind(this);
    }

    componentDidMount() {
        this.getOrders();
    }

    async getOrders() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
            
        };
        const response = await fetch('https://akc-market.com/api/v1/orders', requestOptions);
        const json = await response.json().then( data => {
            this.setState( { orders: data.data.data } );
        } );
    }

    renderOrderStatus( status ) {

        let data = {};

        switch (status) {
            case 'pending':
                data.status = 'Pending';    
                data.class = 'pending';
                break;
            case 'payment_success':
                data.status = 'Payment Success';    
                data.class = 'payment_success';
                break;
            case 'shipped':
                data.status = 'Shipped';    
                data.class = 'shipped';
                break;
            case 'completed':
                data.status = 'Completed';    
                data.class = 'completed';
                break;
            case 'payment_failed':
                data.status = 'Payment Failed';    
                data.class = 'payment_failed';
                break;
            default:
                break;
        }

        return data;
    }

    

    renderOrderPaymentMethod( method ) {

        let data = {};

        switch( method ) {
            case 'paypal':
                data.image = paypal_image;
                data.title = 'Paypal';
                break;
            case 'usdt':
                data.image = usdt_image;
                data.title = 'USDT';
                break;
            default:
                break;
        }

        return data;
    }

    render() {
        if( this.state.orders.length == 0 ) {
            return (
                <>
                    No Record Found
                </>
            )
        }
        return (
            this.state.orders && this.state.orders.map( order => {

                let statusObject = this.renderOrderStatus( order.status );
                let paymentMethodObject = this.renderOrderPaymentMethod( order.payment_method );
                return (
                    <>
                        <div className='merch-history-container'>
                            <div className='merch-history-header'>
                                <div className='history-order'>
                                    <h3 >Order ID</h3>
                                    <p >{order.reference}</p>
                                </div>
                                <div className={'history-orderstatus ' + statusObject.class}> 
                                    <p>{statusObject.status}</p>
                                </div>
                            </div>

                            <div className='hist-payment-method'>
                                <div className='payment-method-item'>
                                    <p> Paid via { paymentMethodObject.title } </p>&nbsp;<img src={ paymentMethodObject.image } width='20px' />
                                </div>
                            </div>

                            <div className='hist-details'>
                                {
                                    order.items.map( item => {
                                        return (
                                            <div className='hist-list-items-1'>
                                                <p className='hist-merch-col-1'>{item.title} (x{item.quantity})</p>
                                                <p className='hist-merch-col-2'>$ {(item.amount * item.quantity).toFixed(2)}</p>
                                            </div>
                                        )
                                    } )
                                }
                                <div className='hist-list-items-1'>
                                    <p className='hist-merch-col-1'>Delivery Fee</p>
                                    <p className='hist-merch-col-2'>$ {order.shipping_fee}</p>
                                </div>
                                <div className='hist-list-items-1'>
                                    <p className='hist-merch-col-1'>Total Payment</p>
                                    <p className='hist-merch-col-2'>$ {order.amount}</p>
                                </div>
                                <div className='hist-list-items-2'>
                                    <p className='hist-merch-col-1'>Delivery Address</p>
                                    <p className='hist-merch-col-3'>{order.address_detail.name} ( {order.address_detail.phone_number} ) </p>
                                    <p className='hist-merch-col-3'>{order.address_detail.detail}</p>
                                </div>
                            </div>
                        </div>
                    </>     
                )
            } )
        )
    }
}

export default MerchHistory;