import React from 'react';
import '../App.css';
import './HeroSection.css';
import { Link } from 'react-router-dom';

const opensea = function() {
    window.open( 'https://opensea.io/collection/akfc', '_blank' );
}

function HeroSection() {

    return (
        
        <div className='wrap-hero'>

            <div className='hero-container'> 
                <div className='hero-heading'>
                    <h1 className='hero-header-1'>A total of </h1>
                    <h1 className='hero-header-2'>9,999 </h1>
                    <h1 className='hero-header-3'>Baby apes were born. </h1>
                </div>


                <div className='hero-btns'>
                    <a className='hero-btn-item-1' href='https://linktr.ee/Apekidsclub'>
                        View on Linktree
                    </a>

                    {/* <Link className='hero-btn-item-2' to='/akfc'>
                        Mint
                    </Link> */}
                </div>

            </div>

        
        </div>
    );
}

export default HeroSection;