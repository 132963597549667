import Marquee from "../Marquee";
import AkfcHeroSection from "../AkfcHeroSection";
import './Akfc.css';
import '../AkfcHeroSection.css';

const logo = require( '../../images/BG-36.png' );
const jt = require('../../images/BG-41.png');
const js = require('../../images/BG-42.png');
const li = require('../../images/BG-15.png');
const kv = require('../../images/BG-14.png');
const intermeta = require('../../images/intermeta-01.png');

const opensea = function() {
    window.open( 'https://opensea.io/collection/akfc', '_blank' );
}

function AKFCInterMeta() {

    return(

        <>
        

            <div id="akfc_raffle">
                {/* <AkfcHeroSection/> */}
                <div>
                    <div className='wrap-akfc-hero'>    
                        <div id="mint_section" class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-4">
                                {/* <div className="intermeta_img">
                                    <img src={intermeta} className="intermeta_logo" alt="Inter Meta FC - AKC" />
                                </div>
                                <div className="mint-title">Now AKFC is being rebranded as Inter Meta FC, Please Join Our</div>
                                
                                <div>
                                    <div class="text-center">
                                        <a href="https://discord.gg/DmVKYQu44G" target="_blank" rel="noreferrer nofollow">
                                            <button className="disc-btn"><i class='bi bi-discord'></i> Discord</button>
                                        </a>
                                        <a href="https://twitter.com/InterMetaFC" target="_blank" rel="noreferrer nofollow">
                                            <button className="twitter-btn"><i class='fab fa-twitter'></i> Twitter</button>
                                        </a>
                                    </div>
                                </div> */}
                                <div className="mint-title">AKFC will join in Metaverse</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="header-title">
                    <div id="mobile_mint">
                        {/* <div className="intermeta_img">
                            <img src={intermeta} className="intermeta_logo" alt="Inter Meta FC - AKC" />
                        </div>
                        <div className="mint-title">
                            <h2>Now AKFC is being rebranded as Inter Meta FC, Please Join Our</h2>
                        </div>
                        
                        <div>
                            <div class="text-center">
                                <a href="https://discord.gg/DmVKYQu44G" target="_blank" rel="noreferrer nofollow">
                                    <button className="disc-btn"><i class='bi bi-discord'></i> Discord</button>
                                </a>
                                <a href="https://twitter.com/InterMetaFC" target="_blank" rel="noreferrer nofollow">
                                    <button className="twitter-btn"><i class='fab fa-twitter'></i> Twitter</button>
                                </a>
                            </div>
                        </div> */}
                        <div className="mint-title">AKFC will join in Metaverse</div>
                    </div>

                    <img id="logo" src={logo} alt="logo"></img>
                    <br></br>
                    <br></br>
                    <div class="subtitle1">
                        We’re by far the Greatest team the Blockchain has ever seen
                    </div>
                    <div class="title">APE KIDS FOOTBALL CLUB</div>
                    <div class="subtitle1">
                        Enjoy Football, Enjoy NFT, Enjoy AKFC
                    </div>
                </div>
                

                
            </div>
        </>
    )
}

export default AKFCInterMeta;