import React from 'react';
import './FAQ.css';
import { Link } from 'react-router-dom';
import Accordion from './FAQAccordion';

function Faq() { 

    const team_header = require('../images/BG-19.png');


    return (

        <div className='wrap-faq' id='faqs'>
            
            <div className='faq-container'>
                <div className='faq-header'>
                    <h1>FAQS</h1>
                </div>

                <div>
                    <Accordion />
                </div>

            </div>
            
            
        </div>
    );
}

export default Faq;