import React from 'react';
import'./MerchLanding.css';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import AddToCartSuccess from './MerchAddtoCartModal'; 


function addToCart( product_id, thumbnail, title, amount, quantity ) {

    let localStorageCart = localStorage.getItem( 'akc_cart' );
    if( localStorageCart == undefined || typeof localStorageCart !== 'string' || localStorageCart == '' ) {
        let data = [ { product_id, title, amount, thumbnail, quantity } ];
        localStorage.setItem( 'akc_cart', JSON.stringify( data ) );
    } else {
        if( localStorageCart.length != 0 ) {
            let cart = JSON.parse( localStorageCart );
            let pos = cart.map( function(e) { return e.product_id } ).indexOf( product_id );
            if( pos != -1 ) {
                if( cart[pos].quantity >= 10 || ( cart[pos].quantity + quantity >= 10 ) ) {
                    cart[pos].quantity = 10;
                } else {
                    cart[pos].quantity = cart[pos].quantity + quantity;
                }
            } else {
                cart.push( { product_id, title, amount, thumbnail, quantity } );
            }
            localStorage.setItem( 'akc_cart', JSON.stringify( cart ) );
        } else {
            let data = [ { product_id, title, amount, thumbnail, quantity } ];
            localStorage.setItem( 'akc_cart', JSON.stringify( data ) );
        }
    }

    if( document.getElementsByClassName( 'cartCount' ).length != 0 ) {
        localStorageCart = localStorage.getItem( 'akc_cart' );
        document.getElementsByClassName( 'cartCount' )[0].innerHTML = JSON.parse(localStorageCart).length;
    }
    
    
}


// Merch add to cart
function MerchProductItems(props) {

    const [modalShow, setModalShow] = React.useState(false);

    let productRender;
    if( props.product_type == 'comingsoon' ) {
        productRender = 
        <li className='merch-product-item'>
            <figure className='merch-item-pic-wrap' data-category={props.label}>
                <img
                className='merch-item-img'
                alt={props.title}
                src={props.src}
                />
            </figure>
            <div className='merch-item-info'>
                <h5 className='merch-item-title'>{props.title}</h5>
                <p className='merch-item-price'>&nbsp;</p>
            </div>
            <div className='merch-addtocart'>
                <Button style={{opacity: '0.5'}}>Coming Soon</Button>
            </div>
        </li>
    } else {
        productRender =
        <li className='merch-product-item'>
            <figure className='merch-item-pic-wrap' data-category={props.label}>
                <img
                className='merch-item-img'
                alt={props.title}
                src={props.src}
                />
            </figure>
            <div className='merch-item-info'>
                <h5 className='merch-item-title'>{props.title}</h5>
                <p className='merch-item-price'>$ {props.price}</p>
            </div>
            <div className='merch-addtocart'>
                <Button onClick={() => setModalShow(true)} >Add to my cart</Button>
                <ConfirmAddtoCartModal
                    product_id={props.product_id}
                    src={props.src}
                    title={props.title}
                    price={props.price}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </li>
    }

    return (
        <>
            { productRender }
        </>
    );

    
// Confirm Add to Cart modal
    function ConfirmAddtoCartModal(props) {

        // const product_1 = require('../images/BG-29.jpg');
        
        let [quantity, setQuantity] = React.useState(1);
    
        const [modalShow, CfmAddtoCartModalShow] = React.useState(false);
        
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Add To My Cart</h4>
                    </Modal.Title>
                </Modal.Header>
    
                <Modal.Body id='Modal-body-1'>
                    <MerchProductItems
                        src={props.src}
                        title={props.title}
                        price={props.price}
                    />
                </Modal.Body>
                <Modal.Body>
                    <div className='item-count-container'>
                        <input className={'item-decrement-btn ' + (quantity > 1 ? 'active' : '')} type="button" value="-" onClick={()=>{if(quantity > 1 ) setQuantity(quantity-=1)}} />
                        <input className='item-input-count' type="text" min="1" max="10" value={quantity + ' pcs'}  />
                        <input className={'item-increment-btn '  + (quantity < 10 ? 'active' : '')} type="button" value="+"  onClick={()=>{if( quantity < 10 ) setQuantity(quantity+=1)}} />
                
                    </div>
                    </Modal.Body>
    
                <Modal.Footer>
                    {/* <Button >Confirm Add</button> */}
                    <Button onClick={() => CfmAddtoCartModalShow(true)} >Confirm Add</Button>
                        <AddToCartSuccess
                            show={modalShow}
                            onHide={() => {
                                CfmAddtoCartModalShow(false);
                                setQuantity(1);
                                addToCart( props.product_id, props.src, props.title, props.price, parseInt( quantity ) );
                                setModalShow(false);
                            }}
                        />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MerchProductItems;