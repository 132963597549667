import React, { useState } from 'react';
import { NavLink } from './NavbarElements';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import Dropdown  from "./Dropdown";

import { Offcanvas, Accordion } from 'react-bootstrap';

const logo = require('../images/BG-20.png');
const logo_pet = require('../images/BG-25.png');

const Navbar = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [dropdown, setDropdown] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    // dropdown
    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(true);
        } 
    };
    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const currentPath = useLocation();

    return (
        <>
            <nav className='navbar1'>
            
                <div>
                    <NavLink to='/' className='navbar-logo'  target='_parent'>
                        <img src={currentPath.pathname == '/akc-pets' ? logo_pet : logo} width='100px' alt='AKC Logo'/>
                    </NavLink>
                </div> 

                <div className='navbar-container'>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <div className='nav-mobile-header'>
                            <i className='fas fa-times' onClick={closeMobileMenu} />
                            <img src={logo} alt='AKC Logo'/>
                            <h2> MENU </h2>
                        </div>

                        <li className='nav-item'>
                            <NavLink to='/#storyline' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                Storyline
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#roadmap' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Roadmap
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#faqs' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                FAQS
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#team' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Team
                            </NavLink>
                        </li>
                        <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <NavLink to='/#' className='nav-links'  >
                                Member <i className='fas fa-caret-down' />
                            </NavLink>
                            
                            {dropdown && <Dropdown  />}
                        </li>
                        {/* <li className='nav-item'>
                            <Link to='/akfc' className='nav-mint-btn'>AKFC Mint</Link>
                        </li> */}

                    </ul>
                </div> {/*  close navbar-container */}



                {/* Mobile view  */}

                <div className='navbar-container-mobile'>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <div className='nav-mobile-header'>
                            <i className='fas fa-times' onClick={closeMobileMenu} />
                            <img src={logo} alt='AKC Logo'/>
                            <h2> MENU </h2>
                        </div>

                        <li className='nav-item'>
                            <NavLink to='/#storyline' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                Storyline
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#roadmap' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Roadmap
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#faqs' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                FAQS
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#team' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Team
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/akfc' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                AKFC
                            </NavLink>
                        </li>
                        <li className='nav-item-dropdown'  >
                            <p className='dropdown-button'>MEMBER <span className='nav-dropdown-icon'><i class="bi bi-chevron-down"></i></span></p>
                            <div className='dropdown-wrapper'>
                                <div class="dropdown-content">
                                    <a href="/akc-pets">AKC PETS </a>
                                    <a href="/akc-merch">Merch</a>
                                    {/* <a href="/akfc">AKFC </a> */}
                                    <a href="/game-center">Game Center</a>
                                    <a href="/">Hold to Earn <span class='mb-dd-coming-soon'>Coming Soon</span></a>
                                    <a href="/">... <span class='mb-dd-coming-soon'>Coming Soon</span></a>
                                </div>
                            </div>
                        </li>
                        {/* <li className='nav-item'>
                            <a href='/akfc' className='nav-mint-btn'>AKFC Mint</a>
                        </li> */}

                    </ul>
                </div> {/*  close navbar-container */}

                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton style={{paddingTop: "1.5rem"}}></Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='nav-mobile-header'>
                            <img src={logo} alt='AKC Logo'/>
                            <br></br>
                            <h2> MENU </h2>
                        </div>
                        <div className="offcanvas-content">
                            <div className="offcanvas-line"></div>
                            <Link to="/#storyline" onClick={handleClose}><div className="offcanvas-menu-title">Storyline</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/#roadmap" onClick={handleClose}><div className="offcanvas-menu-title">Roadmap</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/#faqs" onClick={handleClose}><div className="offcanvas-menu-title">FAQS</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/#team" onClick={handleClose}><div className="offcanvas-menu-title">TEAM</div></Link>
                            <div className="offcanvas-line"></div>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>MEMBER</Accordion.Header>
                                    <div className="offcanvas-line"></div>
                                    <Accordion.Body>
                                        <div className="offcanvas-menu-dropdown-wrapper">
                                            <div className="dropdown-wrapper-left"></div>
                                            <div className="inner-wrapper">
                                                <Link to="/akc-pets" onClick={handleClose}><div className="offcanvas-menu-title">akc pets</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/merch" onClick={handleClose}><div className="offcanvas-menu-title">merch</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/akfc" onClick={handleClose}><div className="offcanvas-menu-title">akfc</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/H4Handwashing" onClick={handleClose}><div className="offcanvas-menu-title">H4Handwashing</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/game-center" onClick={handleClose}><div className="offcanvas-menu-title">game center</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <div className="offcanvas-menu-title comingsoon"><span>hold to earn</span><span className="coming-soon">Coming Soon</span></div>
                                                <div className="offcanvas-line"></div>
                                                <div className="offcanvas-menu-title comingsoon"><span>. . .</span><span className="coming-soon">Coming Soon</span></div>
                                                <div className="offcanvas-line"></div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <div className='nav-social1'>
                    <a className='social-icon-link1 instagram' href='https://www.instagram.com/apekidsclub/' target='_blank' rel='noreferrer' aria-label='Instagram'  >
                        <i class='fab fa-instagram'></i>
                    </a>
                    <a className='social-icon-link1 discord' href='https://discord.gg/apekidsclub' target='_blank' rel='noreferrer' aria-label='Discord'  >
                        <i class='bi bi-discord'></i>
                    </a>
                    <a className='social-icon-link1 twitter' href='https://twitter.com/ApeKidsClub' target='_blank' rel='noreferrer' aria-label='Twitter'  >
                        <i class='fab fa-twitter'></i>
                    </a>
                    
                    <div className='menu-icon' onClick={handleShow}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                </div>
                 

            </nav>
        </>
        
    );
}

export default Navbar;