import React from 'react';
import'./LoadingModal.css';
import { Modal, Spinner } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// Add to Cart Successfully modal
function LoadingModal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='loading-modal'
        >
            <Modal.Header className='loading-header'>
                <Modal.Title  id="contained-modal-title-vcenter">
                    <Spinner animation="border" />
                </Modal.Title>
            </Modal.Header>

        </Modal>
    );
}

export default LoadingModal;