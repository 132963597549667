import React from 'react';
import './Team.css';
import { Link } from 'react-router-dom';


function Team() { 

    const team_header = require('../images/BG-19.png');
    const li_AKC = require('../images/BG-15.png');
    const kunvic_AKC = require('../images/BG-14.png');

    return (

        <div className='wrap-team' id='team'>
            <div className='pre-team-container' id='pre_team'>
                <img className='pre-team-bg' src={team_header} alt='AKC Team BG'/>
            </div>

            <div className='team-container' >
                <div className='team-content'>
                    <div className='team-content-item'>
                        <img src={li_AKC} className='team-displaypic' alt='OG Kid 170' />
                        <div>
                            <div className='team-alias'>OG Kid 170</div>
                            <div className='twitter-name'>
                                <p>Li </p><a href='https://twitter.com/akc_li' target='_blank' rel='noreferrer'>
                                <span className='team-twitter-i'><i class="bi bi-twitter"></i></span></a>
                        
                            </div>
                            <div className='role-title'>Co-Founder</div>
                        </div>
                    </div>
                    <div className='team-content-item'>
                        <img src={kunvic_AKC} className='team-displaypic' alt='BAYC 4928' />
                        <div>
                            <div className='team-alias'>BAYC 4928</div>
                            <div className='twitter-name'>
                                <p>Kun Vic </p><a href='https://twitter.com/VictoriorCG' target='_blank' rel='noreferrer'>
                                <span className='team-twitter-i'><i class="bi bi-twitter"></i></span></a>
                            </div>
                            <div className='role-title'>Co-Founder (Artist)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;