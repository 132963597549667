import React from 'react';
import'./MerchLanding.css';
import { Button } from 'react-bootstrap';


function MerchNoStock(props) {

    const nostock_logo = require('../images/BG-50.png');

    return (
        <>
            <li className='nostock-product-item'>
                <figure className='nostock-item-pic-wrap' data-category={props.label}>
                    <img
                    className='nostock-item-img'
                    alt={props.title}
                    src={props.src}
                    />
                </figure>
                <div className='nostock-item-info'>
                    <div className='nostock-item-info-1'>
                        <h5 className='nostock-item-title'>{props.title}</h5>
                        <p className='merch-item-price'>$ {props.price}</p>
                    </div>
                    <div className='nostock-item-info-2'>
                        <img src={nostock_logo} className='nostock-logo' />
                    </div>
                </div>
                <div className='nostock-btn'>
                    <Button  >Out of stock</Button>
                </div>
                
            </li>
        </>
    );
}

export default MerchNoStock;