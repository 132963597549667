import React from 'react';
import './MerchLanding.css';
import './MerchCartItem.css';

import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';

let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';
let test = 0;

function setCart() {

    let data = [
        {
            title: 'AKC',
            amount: 29.99,
            thumbnail: 'https://akc-market.com/storage/products/AKC_CAP/3Mnpe77McVzruYwDBKBB1tGrl2ShVqg4xaK91P6c.jpg',
            quantity: 10
        },
        {
            title: 'AKC 2',
            amount: 39.99,
            thumbnail: 'https://akc-market.com/storage/products/AKC_CAP/3Mnpe77McVzruYwDBKBB1tGrl2ShVqg4xaK91P6c.jpg',
            quantity: 1
        },
    ]

    localStorage.setItem( 'akc_cart', JSON.stringify( data ) );
}

// setCart();

function getCarts() {
    
    let data = {};
    data.cart = [];
    data.cartAmount = 0;
    let localStorageCart = localStorage.getItem( 'akc_cart' );
    if( localStorageCart == undefined || typeof localStorageCart !== 'string' || localStorageCart == '' ) {
        return data;
    }

    let cart = JSON.parse( localStorage.getItem( 'akc_cart' ) );
    if( cart.length != 0 ) {
        cart.forEach(element => {
            data.cartAmount+=(parseFloat(element.amount)*element.quantity);
        });
        data.cart = cart;
    }

    return data;
}

function MerchCart() {

    const cart_item = require('../images/BG-29.jpg');
    const cartObject = getCarts();

    let [updateCartItem, setUpdateCartItem] = React.useState(0);

    function updateCart( operator, product_id, quantity ) {
    
        let localStorageCart = localStorage.getItem( 'akc_cart' );
        let cart = JSON.parse( localStorageCart );
        let pos = cart.map( function(e) { return e.product_id } ).indexOf( product_id );
    
        // if( operator == '+' ) {
        //     cart[pos].quantity = cart[pos].quantity + 1;
        // } else {
        //     cart[pos].quantity = cart[pos].quantity - 1;
        //     if( cart[pos].quantity == 0 ) {
        //         cart.splice( pos, 1 );
        //     }
        // }
        
        if( operator == 'x' ) {
            cart.splice( pos, 1 );
        } else if( operator == '~' ) {
            cart[pos].quantity = parseInt( quantity );
        }
    
        localStorage.setItem( 'akc_cart', JSON.stringify( cart ) );
        setUpdateCartItem(updateCartItem+=1);
    }

    return (
        
        <div className='wrap-merchcart'>

            <div className='merchcart-header-container'>
                <div className='merchcart-header'>
                    <h2 className='merchcart-header-member'>Member</h2>
                    <i className="fas fa-solid fa-angle-left" />
                    <h2 className='merchcart-header-member'>Merch</h2>
                    <i className="fas fa-solid fa-angle-left" />
                    <h2 className='merchcart-header-merch'>My Cart</h2>
                </div>
                
                <div className='close-merchcart'>
                    <Link to='/merch' className='close-cart-link'><i className="bi bi-x"></i></Link>
                </div>
            </div>


            <div className='merchcart-container'>
                <div className='total-cart-items'>
                    <p>{ cartObject.cart.length } items</p>
                </div>

                <div className='merchcart-content'>
                    {
                        cartObject.cart.length == 0 ? <div className="text-center"><br/>No Cart Item</div> : ''
                    }
                    {
                        cartObject.cart.map( data => {
                            return (
                                <>
                                <div className="merchcart-list">
                                    <input type="checkbox" />
                                    <img src={data.thumbnail} className='merchcart-item-img' />
                                    <div className='merchcart-item-desc'>
                                        <h4 className='merchcart-item-name'>{data.title} </h4>
                                        <p className='merchcart-item-price'>$ {data.amount} </p>
                                        {/* <div>
                                            <Button variant='outline-dark' style={{padding: '0 0.5rem'}} onClick={()=>updateCart( '-', data.product_id )}>-</Button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button variant='outline-dark'  style={{padding: '0 0.5rem'}} onClick={()=>updateCart( '+', data.product_id )}>+</Button>
                                        </div> */}
                                    </div>
                                    <div className='merchcart-quantity'>
                                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                        <Button variant='outline' className='delete-item' style={{}} onClick={()=>updateCart( 'x', data.product_id )}>Delete item</Button>
                                        &nbsp;
                                        &nbsp;
                                        <Form.Select className='select-quantity' value={data.quantity} onChange={e=>updateCart( '~', data.product_id, e.target.value )}>
                                            <option value="1">Qty x1</option>
                                            <option value="2">Qty x2</option>
                                            <option value="3">Qty x3</option>
                                            <option value="4">Qty x4</option>
                                            <option value="5">Qty x5</option>
                                            <option value="6">Qty x6</option>
                                            <option value="7">Qty x7</option>
                                            <option value="8">Qty x8</option>
                                            <option value="9">Qty x9</option>
                                            <option value="10">Qty x10</option>
                                        </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        } )
                    }
                    {/* <div className='merchcart-list'>
                        <input className='merch-checkbox' type="checkbox" />
                        <img src={cart_item} className='merchcart-item-img' />
                        <div className='merchcart-item-desc'>
                            <h4 className='merchcart-item-name'>AKC Fashion Cap </h4>
                            <p className='merchcart-item-price'>$ 29.99 </p>
                        </div>
                        <p className='merchcart-quantity'>x1</p>
                    </div>

                    <div className="merchcart-list">
                        <input type="checkbox" />
                        <img src={cart_item} className='merchcart-item-img' />
                        <div className='merchcart-item-desc'>
                            <h4 className='merchcart-item-name'>AKC Fashion Cap </h4>
                            <p className='merchcart-item-price'>$ 29.99 </p>
                        </div>
                        <p className='merchcart-quantity'>x1</p>
                    </div> */}
                </div>
            </div>

                <div className='merchcart-price-sum'>
                    <div className='merchcart-summary'>
                        <p className='merchcart-sum-text'>Total Price</p>
                        <p className='merchcart-sum-price'>$ {cartObject.cartAmount.toFixed(2)}</p>
                    </div>

                <div className='merchcart-final-purc'>
                    {/* <button >Confirm purchase</button> */}
                    <Link to='/merch-payment' >Confirm purchase</Link>
                </div>
            </div>

        </div>
    );
}

export default MerchCart;





