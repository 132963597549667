import React from 'react';
import './Roadmap.css';
import { Link } from 'react-router-dom';


function Roadmap() { 

    const roadmap_1 = require('../images/BG-22.png'); //ongoing
    const roadmap_2 = require('../images/BG-07.png');
    const roadmap_3 = require('../images/BG-23.png'); //completed
    const roadmap_4 = require('../images/BG-11.png');
    const roadmap_5 = require('../images/BG-08.png');
    const roadmap_6 = require('../images/BG-10.png');
    const roadmap_7 = require('../images/BG-12.png');

    return (

        <div className='wrap-roadmap' id='roadmap'>
            
            <div className='roadmap-container'>
                <h1 className='roadmap-title'>Road map</h1>

                <div className='version-wrapper'>
                    <div className='roadmap-version'>
                        <div>
                            <p>Road map 1.0</p>
                            <div className='version-line selected'></div>
                        </div>
                        <div className='coming'>
                            <p>Road map 2.0 </p>
                            
                            <span className='coming-soon-wrap'>Coming Soon</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 01 --> */}
            <div className='rdm-content-1'>
                <div className='rdm-content-row-1 rm01'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>01</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_3}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>Back to School</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>
                                    Interactive Learning Channel in Discord
                                    </p>
                                </div>
                                <div className='rm-content'>
                                    <p>
                                    AKC will be engaging with different knowledgeable or experience
                                    people from blockchain to have interactive learning in our community,
                                    which will be presented on various social media including YouTube,
                                    Twitter Space, Medium and etc.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rdm-content-row-2-1'>
                    <img className='rdm-image-1' src={roadmap_2} alt='Back to School' />
                </div>
            </div>

            {/* <!-- 02 --> */}
            <div className='rdm-content-2'>
                <div className='rdm-content-row-2-2'>
                    <img className='rdm-image-2' src={roadmap_5} alt='AKC Merch' />
                </div>

                <div className='rdm-content-row-2 rm02'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>02</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_3}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>AKC Merch Coming</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>Kids Merchandise Shop</p>
                                </div>
                                <div className='rm-content'>
                                    <a className='rdm-btn-item-1' href='/merch'>
                                        View AKC Merch
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            {/* <!-- 03 --> */}
            <div className='rdm-content-1'>
                <div className='rdm-content-row-1 rm03'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>03</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_3}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>Dance Party</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>We would like to surprise all AKC nft holders</p>
                                </div>
                                <div className='rm-content'>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rdm-content-row-2-3'>
                    <iframe className='iframe-vid-3' width="350" height="350" src="https://www.youtube.com/embed/nqejkJNSqXU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                </div>
            </div>

            {/* <!-- 04 --> */}
            <div className='rdm-content-2'>
                <div className='rdm-content-row-2-4'>
                    <img className='rdm-image-2' src={roadmap_6} alt='AKC Merch' />
                </div>

                <div className='rdm-content-row-2 rm04'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>04</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_1}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>AKC Charity [On-Going]</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>Kids Merchandise Shop</p>
                                </div>
                                <div className='rm-content'>
                                    <ul>
                                        <li>AKC Charity [On-Going]</li>
                                        <li>AKC Token [On-Hold]</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- 05 --> */}
            <div className='rdm-content-1'>
                <div className='rdm-content-row-1 rm05'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>05</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_3}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>Kid's Presents</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>12K AKC Pets Minted for Free</p>
                                </div>
                                <div className='rm-content'>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rdm-content-row-2-5'>
                    <img className='rdm-image-1' src={roadmap_4} alt="Kid's Presents" />
                </div>
            </div>

            {/* <!-- 06 --> */}
            <div className='rdm-content-2'>
                <div className='rdm-content-row-2-6'>
                    <img className='rdm-image-2' src={roadmap_7} alt='AKC Merch' />
                </div>

                <div className='rdm-content-row-2 rm06'>
                    <div className='rdm-content-row-items' >
                        <div className='rdm-row-item-1 '>
                            <h2 className='rm-index'>06</h2>
                            
                            <img className='rdm-content-img-1' src={roadmap_3}  />
                        </div>
                        <div className='rdm-row-item-2 rm-number '>
                            <div className='rdm-row-item-2-1'>
                                <div className='rm-title'>
                                    <h3>4 Easter egg traits will be hidden inside our NFTs.</h3>
                                </div>
                                <div className='rm-description'>
                                    <p>These 4 traits are super rare and will reveal our future plans for the Ape Kids Club!</p>
                                </div>
                                <div className='rm-content'>
                                    <p>1. AKC are going to have Pets. (Completed)</p>
                                    <p>2. BTC, ETH and Piggy Banks show AKC are going to have its’ own token economy. (Q2, 2022)</p>
                                    <p>3. TV hints we are going to have Ape Kids Multiverse. (Q2, 2022)</p>
                                    <p>4. AKC are going to have 3D and animated version.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Roadmap;