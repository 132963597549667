import './Music.css';

function Music() {
    return (
        <div className='music-section'>
            <div>
                <iframe class="music" src="https://www.youtube.com/embed/KYn3rhiBL-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
            <div class="description">
                Christmas is around the corner and the Ape Kids are making wishes to receive a pet to be their Christmas gift. Santa heard the kids' wishes and prepared Puppy, Cat, Birds and Fish to gift them
            </div>
            <div className="space-divider"></div>
        </div>
    )
}

export default Music;