import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';


function Footer() { 

    const footer_1 = require('../images/BG-16.png');

    return (

        <div className='wrap-footer' id='footer'>
            <div class='footer-container'>
                <div class='footer-content-1'>
                    <img class='footer-apk-img' src={footer_1} />
                    <div class='footer-text'>
                        <h4 class="mb-0">We are building Ape Kids Universe</h4>
                    </div>
                    <div class='footer-copyright'>
                        <p class="mb-0">©2022 ApeKidsClub. All Rights Reserved.</p>
                    </div>
                </div>
                <div class='footer-content-2'>
                    <div class='footer-menu'>
                        <ul class='footer-links'>
                            <li className='footer-item'>
                                <Link to='/#' className='footer-link-item' target='_parent' >
                                    Home
                                </Link>
                            </li>
                            <li className='footer-item'>
                                <Link to='/#storyline' className='footer-link-item' target='_parent' >
                                    Storyline
                                </Link>
                            </li>
                            <li className='footer-item'>
                                <Link to='/#roadmap' className='footer-link-item' target='_parent'>
                                    Roadmap
                                </Link>
                            </li>
                            <li className='footer-item'>
                                <Link to='/#team' className='footer-link-item' target='_parent'>
                                    Team
                                </Link>
                            </li>
                            <li className='footer-item'>
                                <Link to='/#' className='footer-link-item' target='_parent'>
                                    Terms & Conditions
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class='footer-sc-icons'>
                        <a class='footer-icon-link instagram' href='https://www.instagram.com/apekidsclub/' target='_blank' rel='noopener' aria-label='Instagram'  >
                            <i class='fab fa-instagram'></i>
                        </a>
                        <a class='footer-icon-link discord' href='https://discord.gg/apekidsclub' target='_blank' rel='noopener' aria-label='Discord'  >
                            <i class='bi bi-discord'></i>
                        </a>
                        <a class='footer-icon-link twitter' href='https://twitter.com/ApeKidsClub' target='_blank' rel='noopener' aria-label='Twitter'  >
                            <i class='fab fa-twitter'></i>
                        </a>
                       
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;