import React, { useState } from 'react';
import { Data } from './FAQanswers';
import styled from 'styled-components';
// import { IconContext } from 'react-icons';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './FAQ.css';

import { Accordion } from 'react-bootstrap';

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 1rem;
  background-color: transparent;

`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 30%;
`;

const Wrap = styled.div`
  width: 100%;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 1rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  h1 {
    padding: 2rem 2rem 1rem;
    border-radius: 15px;
    color: #1c4a7d;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Calibri';
  }
  span {
    margin: 12px 0 12px auto;
    padding: 24px 24px 0 ;
    
  }
`;

const Dropdown = styled.div`
  background: #fff;
  color: #00ffb9;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    padding: 0 2rem 1rem;
    color: #8ea6c0;
    font-size: 20px;
    font-family: 'Calibri';
    margin-right: auto;
  }
`;

const Accordion_ = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {

      return setClicked(null);
    }

    setClicked(index);
  };

  return (

    <Accordion id="akc_accordion" flush>
    {Data.map((item,index)=>{
      console.log(index.toString());
      return (
        <Accordion.Item className="akc-accordion" key={index} eventKey={index.toString()}>
          <Accordion.Header>{item.question}</Accordion.Header>
          <Accordion.Body>
            {item.answer}
          </Accordion.Body>
        </Accordion.Item>
      )
    })}
    </Accordion>

    // <IconContext.Provider value={{ color: '#1c4a7d', size: '25px' }}>
    //   <AccordionSection>
    //     <Container>
    //       {Data.map((item, index) => {
    //         return (
    //           <>
    //           <div onClick={() => toggle(index)} key={index}>

    //             <Wrap className="accordion-container">
    //               <div className="accordion-container-items">
    //                 <h1>{item.question}</h1>
    //                 <span>{clicked === index ? <FiChevronUp  /> : <FiChevronDown />}</span>

    //               </div>
                  
    //               <div>
    //                 {clicked === index ? (
    //                   <Dropdown className="testing-transition">
    //                     <p>{item.answer}</p>
    //                   </Dropdown>
    //               ) : null}
    //               </div>
    //             </Wrap>

    //           </div>

    //           </>
    //         );
    //       })}
    //     </Container>
    //   </AccordionSection>
    // </IconContext.Provider>
  );
};

export default Accordion_;