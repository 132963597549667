import './GameCenterHeroSection.css';

function GameCenterHeroSection() {

    const akc_pet = require('../images/BG-25.png');

    const pic1 = require('../images/BG-55.png');
    const pic2 = require('../images/BG-54.png');

    return (
        <div style={{position:"relative"}}>
            <div className='wrap-game-center-hero'>
                <div className='game-center-header-container'>
                    <div className='game-center-header'>
                        <h2 className='game-center-header-member'>Member</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='game-center-header-text'>Game Center</h2>
                    </div>
                </div>
            </div>
            <div className='game-center-header-title'>
                <div className="title">GAME CENTER</div>
                <div className='game-center-pictures'>
                    <div className='img-wrapper'>
                        <a target={"_blank"} href='https://apps.apple.com/tt/app/ape-kids-run/id1609034746'><img src={pic1} style={{maxWidth: "100%",borderRadius: "20px",cursor:"pointer"}}/></a>
                    </div>
                    <div className='img-wrapper'>
                        <a target={"_blank"} href='https://docs.apekids.world/welcome-to-apekidsclub'><img src={pic2} style={{maxWidth: "100%",borderRadius: "20px",cursor:"pointer"}}/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GameCenterHeroSection;