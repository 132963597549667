import './AkfcHeroSection.css';

function AkfcHeroSection() {

    const akc_pet = require('../images/BG-25.png');

    const opensea = function() {
        window.open( 'https://opensea.io/collection/akfc', '_blank' );
    }

    return (
        <div>
            <div className='wrap-akfc-hero'>    
                <div id="mint_section" class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-4">
                        <div class="mint-title">Sale is over</div>
                        
                        <div>
                            <div class="text-center">
                                {/* <button class="btn btn-primary raffle-now metamask" id="raffle">
                                    <a href='https://opensea.io/collection/akfc' id="raffle_text" target='_blank' rel='noreferrer'>&nbsp;Purchase on Opensea</a>
                                </button> */}
                                <a href='https://opensea.io/collection/akfc' id="raffle_text" target='_blank' rel='noreferrer'><button class="btn btn-primary raffle-now metamask" id="raffle">&nbsp;Purchase on Opensea</button></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AkfcHeroSection;