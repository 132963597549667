import React, { useState } from 'react';
import { MenuItems } from './DropdownMenuItems';
import './Navbar.css';
import { Link } from 'react-router-dom';

function Dropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>

      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu1 clicked' : 'dropdown-menu1'}
      >

        {MenuItems.map((item, index) => {
          return (
            <li key={index} className='dropdown-link-items'>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}

                <i className='fas fa-caret-right' />

                <span class='dd-coming-soon'>Coming Soon</span>
              </Link>
            </li>
          );
        })}
      </ul>

    </>
  );
}

export default Dropdown;