import React, { Component } from 'react';

import MerchProductItems from './MerchProductItems';
import MerchNoStock from './MerchNoStock';

let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

class MerchProductItemsRender extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
        };
        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    async getProducts() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
            
        };
        const response = await fetch('https://akc-market.com/api/v1/products', requestOptions);
        const json = await response.json().then( data => {
            this.setState( { products: data.data.data } );
        } );
    }
//
    render() {
        return (
            this.state.products && this.state.products.map( product => {

                let preorder = '';
                if( product.type == 'preorder' ) {
                    preorder = ' (Pre-order Coming)';
                }

                if( product.inventory.quantity == 0 ) {
                    return (
                        <MerchNoStock
                        product_id={product.id}
                        src={product.thumbnail}
                        title={product.title+preorder}
                        price={product.price}
                        />
                    )
                } else {
                    return (
                        <MerchProductItems
                        product_id={product.id}
                        product_type={product.type}
                        src={product.thumbnail}
                        title={product.title+preorder}
                        price={product.price}
                        />
                    )
                }
            } )
        )
    }
}

export default MerchProductItemsRender;