import './MerchLanding.css';

import LoadingModal from './LoadingModal';
import MerchProductItemsRender from './MerchProductItemsRender';
import MerchHistory from './MerchHistory';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Tabs, Tab } from 'react-bootstrap';

import { ethers, providers } from 'ethers';
import akcContract from '../abi/ApeKidsClub_v2_abi.json';
const contractAddress = '0x9bf252f97891b907f002f2887eff9246e3054080';
let token = localStorage.getItem( 'akc_token' ) ? localStorage.getItem( 'akc_token' ) : '';

class MerchLandingRe extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
            defaultTab: 'merch',
            loginMetamask: '',
            notOwnAKC: 'hide',
            productListing: 'hide',
            showModal: true,
        };
        this.getProducts = this.getProducts.bind(this);
        this.connectMetamask = this.connectMetamask.bind(this);
    }

    componentDidMount() {
        this.getProducts();

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if( params.get('tab') == 'history' && params.get( 'status' ) == 'success' ) {
            localStorage.setItem( 'akc_cart', [] );
        }

        const localStorageCart = localStorage.getItem( 'akc_cart' );
        if( localStorageCart == undefined || typeof localStorageCart !== 'string' || localStorageCart == '' ) {
            if( document.getElementsByClassName( 'cartCount' ).length != 0 ) {
                document.getElementsByClassName( 'cartCount' )[0].innerHTML = 0;
            }
        } else {
            if( document.getElementsByClassName( 'cartCount' ).length != 0 ) {
                document.getElementsByClassName( 'cartCount' )[0].innerHTML = JSON.parse(localStorageCart).length;
            }
        }


    }

    async getProducts() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
        };
        const response = await fetch('https://akc-market.com/api/v1/products', requestOptions).then( response => {
            if( response.status == 200 ) {
                this.setState( { showModal: false, productListing: '', loginMetamask: 'hide', notOwnAKC: 'hide' } );
            } else {
                this.setState( { showModal: false } );
            }

        } );
    }

    async connectMetamask() {

        const { ethereum } = window;
        console.log( ethereum );
        if( !ethereum ) {
          console.log( 'Please install metamask' );
        }
      
        try {
            const accounts = await ethereum.request( { method: 'eth_requestAccounts'} );
            
            if( accounts.length != 0 ) {
      
                const providers = new ethers.providers.Web3Provider(ethereum);
                const signer = providers.getSigner();
                const contract = new ethers.Contract( contractAddress, akcContract, signer );
      
      
                // metamask authentication function for akc
                let test = await contract.walletOfOwner(accounts[0]);
                if( test.length == 0 ) {
                    // cannot login because not own akc
                    // show not own akc page
                    // setLoginMetamask('hide');
                    // setNotOwnAKC('');
                    // setProductListing('hide');
                    this.setState( { loginMetamask: 'hide', notOwnAKC: '', productListing: 'hide' } );
                    return 0;
                }
      
                const message = [
                "I have read and accept the terms and conditions",
                ].join("\n");
      
                const signature = await ethereum.request( { method: 'personal_sign', params: [ message, accounts[0] ] } );
        
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ metamask_address: accounts[0], message, signature })
                };
                const response = await fetch('https://akc-market.com/api/v1/users', requestOptions);
                const data = await response.json();
        
                if( typeof data.token !== 'undefined' ) {
                // store token in localstorage
                token = data.token;
                localStorage.setItem( 'akc_token', token );
                window.location.reload();
                } else {
                // throw error message
                alert( data.message );
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    render() {

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        const metamask_icon = require('../images/BG-49.png');
        const akc_icon = require('../images/BG-20.png');

        return (
            <div className='wrap-merch'>
                <div className='merch-header-container'>
                    <div className='merch-header'>
                        <h2 className='merch-header-member'>Member</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='merch-header-merch'>Merch</h2>
                    </div>
                    <div className='view-cart-items'>
                        <Link to="/merch-cart" className='view-all-cart-link'>
                            <p>My Cart Items [ <span class="cartCount">0</span> ] </p>
                            <span aria-hidden="true" data-icon="&#xe903;" className='view-cart-icon'></span>
                        </Link>
                    </div>
                </div>


                {/* Connect metamask */}
                <div className={'merch-container ' + this.state.loginMetamask}>
                    <div className='merch-content-p1'>
                        <div className='m-c-p1-item-1'>
                            <h1>Merch</h1>
                        </div>
                        <div className='m-c-p1-item-2'>
                            <img src={metamask_icon} />
                            <p>Please connect Metamask <br></br> to continue </p>
                        </div>
                        <div className='m-c-p1-item-3'>
                            <button onClick={this.connectMetamask}>Connect MetaMask</button>
                        </div>
                    </div>
                </div>

                {/* Metamask does not have AKC */}
                <div className={'merch-container ' + this.state.notOwnAKC}>
                    <div className='merch-content-p2'>
                        <div className='m-c-p2-item-1'>
                            <h1>Merch</h1>
                        </div>
                        <div className='m-c-p2-item-2'>
                            <img src={akc_icon} />
                            <p className='merch-requirement-1'>Sorry you’re not AKC owner
                            <br></br>Please purchase AKC on Open Sea. </p>
                            <p className='merch-requirement-2'>You need at least 1 akc nft to purchase merch<br></br>  to continue </p>
                        </div>
                        <div className='m-c-p2-item-3'>
                            <a href="https://opensea.io/collection/akc" target="_blank" rel="noreferrer">View on OpenSea </a>
                    
                        </div>
                    </div>
                </div>

                {/* Show Merch products */}
                <div className={'merch-container ' + this.state.productListing }>
                    <div className='m-c-p1-item-1'>
                            <h1>Merch</h1>
                    </div>
                    <div className='merch-content-display'>
                        <Tabs defaultActiveKey={ () => { if( params.get('tab') == 'history' ) {return 'history'} else { return 'merch' } } } id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="merch" title="Merch" className='merch-tab'>
                                <div className='m-c-display-header'>
                                    <h3>Welcome to AKC Merch!</h3>
                                    <p>Let’s get our collectible items and more! </p>
                                </div>
                                <div className='m-c-display-items'>
                                    <div className='merch-items-1'>
                                        <ul className='merch-items'>
                                            <MerchProductItemsRender/>
                                            <li className='merch-product-coming'>
                                                <figure className='merch-item-pic-coming'>
                                                    <img src={akc_icon} alt='AKC Logo' />
                                                </figure>
                                                <div className='merch-item-coming'>
                                                    <h5 className='merch-coming-title'>More are coming!</h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="history" title="History" className='history-tab'>
                                <MerchHistory />                           
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <LoadingModal show={this.state.showModal}/>
            </div>
        )
    }
}

export default MerchLandingRe;