import '../../App.css';
import MerchCart from '../MerchCartItem';


function Merch() {
    return (
      <>
      
        <MerchCart />
        
      </>
    )
  }
  
  export default Merch;