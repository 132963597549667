import React from 'react';
import'./MerchAddtoCartModal.css';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

// Add to Cart Successfully modal
function AddToCartSuccess(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='AddtoCartSuccess-modal'
        >
            <Modal.Header className='AddtoCartSuccess-header'>
                <Modal.Title  id="contained-modal-title-vcenter">
                    <div className='AddtoCartSuccess-contents'> 
                        <h4>
                            <span aria-hidden="true" data-icon="&#xe901;" className='AddtoCartSuccess-icon'></span>
                            Successfully added to My Cart
                        </h4>
                    </div>
                    
                </Modal.Title>
            </Modal.Header>

        </Modal>
    );
}

export default AddToCartSuccess;