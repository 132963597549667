import React from 'react';
import './Marquee.css';
import { Link } from 'react-router-dom';


function Marquee( props ) { 

    const marquee_1 = require('../images/' + props.image);

    return (

        <div id="marquee" className={'wrap-marquee ' + props.class}>
            <div class=" marquee-container">
                <div class="marquee" >
                    <div class="horizontal-scroll">
                        <img src={marquee_1} alt="slider-image" />
                    </div>
                </div>
                <div class="marquee" >
                    <div class="horizontal-scroll">
                        <img src={marquee_1} alt="slider-image" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Marquee;