import '../../App.css';
import MerchLanding from '../MerchLanding';
import MerchLandingRe from '../MerchLandingRe';

// import { ethers, providers } from 'ethers';
// import akcContract from '../../abi/ApeKidsClub_v2_abi.json';
// const contractAddress = '0x9bf252f97891b907f002f2887eff9246e3054080';

// let token = '';

// async function connectMetamask() {

//   const { ethereum } = window;
//   console.log( ethereum );
//   if( !ethereum ) {
//     console.log( 'please install metamask' );
//   }

//   try {
//     const accounts = await ethereum.request( { method: 'eth_requestAccounts'} );
    
//     if( accounts.length != 0 ) {

//       const providers = new ethers.providers.Web3Provider(ethereum);
//       const signer = providers.getSigner();
//       const contract = new ethers.Contract( contractAddress, akcContract, signer );


//       // command this lateer
//       // let test = await contract.walletOfOwner(accounts[0]);
//       // if( test.length == 0 ) {
//       //   // cannot login because not own akc
//       //   // show not own akc page
//       //   console.log( 'not own akc' );
//       //   return 0;
//       // }

//       const message = [
//         "I have read and accept the terms and conditions",
//       ].join("\n");

//       const signature = await ethereum.request( { method: 'personal_sign', params: [ message, accounts[0] ] } );

//       const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
//         body: JSON.stringify({ metamask_address: accounts[0], message, signature })
//       };
//       const response = await fetch('https://akc-market.com/api/v1/users', requestOptions);
//       const data = await response.json();

//       if( typeof data.token !== 'undefined' ) {
//         // store token in localstorage
//         token = data.token;
//         // switch the view to login
//       } else {
//         // throw error message
//         alert( data.message );
//       }
//     }
//   } catch (error) {
//     console.log(error)
//   }

// }

// async function getProducts() {
//   const requestOptions = {
//     method: 'GET',
//     headers: { 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
    
//   };
//   const response = await fetch('https://akc-market.com/api/v1/products', requestOptions);
//   const data = await response.json();
//   console.log( data );
// } 


function Merch() {
  return (
    <>
      <MerchLandingRe />
      {/* <HeroSection /> */}

      {/* <div>
        <button onClick={connectMetamask}>Conenct MetaMask</button>
        &nbsp;
        <button onClick={getProducts}>Get Products</button>
      </div> */}
    </>
  )
}

export default Merch;