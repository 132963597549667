import './Charity_Page_Items.css';

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";

function Charity_Page_Items() {

    const handwash_1 = require('../images/Home-Banner-2.png');
    
    const h1 = require('../images/H1.jpg');
    const h2 = require('../images/H2.jpg');
    const h3 = require('../images/H3.jpg');
    const h4 = require('../images/H4.jpg');
    const h5 = require('../images/H5.jpg');
    const h6 = require('../images/H6.jpg');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let navigate = useNavigate();

    return (
        <div className="wrap-charity">
            <div className='wrap-charity-hero'>
                <div className='charity-header-container'>
                    <div className='charity-header'>
                        <h2 className='charity-header-member'>Member</h2>
                        <i className="fas fa-solid fa-angle-left" />
                        <h2 className='charity-header-text'>H4Handwashing</h2>
                    </div>
                </div>
            </div>

            <div className='charity-container'>
                <div className='charity-container-header'>
                    <h1 style={{textAlign: "left"}}>Thank you for participating, all 999 NFT is fully redeemed.</h1>
                </div>

                <div className="charity-content-1">
                    <p>The NFT recipients will receive their NFT in their NFT wallet after the closing date. 31st October, 2022, 23:59pm Singapore Time.</p>

                    <div className="char-yt-container">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/UGb5f2OQnR0" 
                            title="Lifebuoy inspires ‘H for Handwashing’ NFT created for good" 
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen=""> 
                        </iframe>
                    </div>

                    <div className="six-characters">
                        <div>
                            <img src={h1} />
                        </div>
                        <div>
                            <img src={h2} />
                        </div>
                        <div>
                            <img src={h3} />
                        </div>
                        <div>
                            <img src={h4} />
                        </div>
                        <div>
                            <img src={h5} />
                        </div>
                        <div>
                            <img src={h6} />
                        </div>
                    </div>

                    <br></br>

                    <p>Whilst Lifebuoy is known for selling soap, they are also a leader in driving social change and positive behaviours in communities. To date, Lifebuoy has helped more than 1 billion people adopt better handwashing habits to help keep germs at bay.</p>
                
                    <p>Triveni Rajagopal, Personal Care Digital Transformation Lead, Unilever, comments, "We will continue to use our scale and global profile to help set future-fit foundations for our business and help communities. The metaverse allows us to think of new opportunities to supercharge Lifebuoy's commitments to make handwashing with soap a daily habit for millions of children worldwide. Lastly, it provides new ways to connect with new consumers like never before."</p>

                </div>

                <div className="charity-content-2">
                    <div>
                        <h3>Handwashing with soap saves lives.</h3>
                        <h3>5 facts you did not know about handwashing and now you know!</h3>
                        <br></br>
                        <p>1. Pneumonia and diarrhea are the top 2 fatal diseases causing child death.<br></br>
                        2. 1 child dies from pneumonia or diarrhea every 30 seconds.<br></br>
                        3. Hands are the principal carriers of disease-causing germs and it’s estimated that if handwashing with soap is widely practised, approximately 230,000 deaths could be averted every year.<br></br>
                        4. The simple practice of handwashing with soap is the single most cost-effective intervention to prevent child deaths. It is proven to reduce diarrhoeal disease by up to 45% and pneumonia by 23%.<br></br>
                        5. Lifebuoy achieved our <a target="_blank" href="https://assets.unilever.com/files/92ui5egz/production/16cb778e4d31b81509dc5937001559f1f5c863ab.pdf/USLP-summary-of-10-years-progress.pdf">Unilever Sustainable Living Plan</a> target to help more than <a target="_blank" href="https://www.unilever.com/news/news-search/2020/how-lifebuoy-scaled-up-to-save-lives/">1 billion</a> people develop good handwashing habits, reaching over 486 million people in 30 countries.</p>
                    </div>
                </div>

            </div>

            <Modal show={show} className="tnc_modal" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Lifebuoy 31st October 2022 AKC Handwashing NFT</h3>
                    <ul>
                        <li>
                        The promotion in the form of the Giveaway is being done by the brand Lifebuoy (“Lifebuoy”) in association with the Ape Kids Club (“AKC”). The Giveaway will be hosted on the Ape Kids Club (“AKC”) website.
                        </li>
                        <li>
                        As part of the Lifebuoy “H for Handwashing” campaign, the Giveaway is an opportunity to win a free H4H NFT (the “NFT”) to use on the MetaMask wallet platform (the “Giveaway”). The Giveaway will commence on 15th October, 2022 and will close on 31st October, 2022.
                        </li>
                        <li>
                        The Giveaway is open to all eligible participants holding a valid MetaMask crypto wallet: <a target="_blank" href="https://metamask.io/">https://metamask.io/</a>. 
                        </li>
                        <li>
                        To download the NFT, you must successfully complete the handwashing-related quiz on the Apekidsclub.io website (the”Quiz”). There is no limitation on the number of attempts that you can make at answering the Quiz. Once you have successful completed the Quiz, you will be entitled to download one free H4H NFT which must be linked to your MetaMask crypto wallet. The NFT can only be accessed via the MetaMask platform. Each MetaMask wallet holder will be entitled to claim a maximum of one free H4H NFT upon successful completion of the Quiz.
                        </li>
                        <li>
                        By entering the Giveaway you agree to provide the details of your MetaMask crypto wallet ID. All personal data will be processed in accordance with AKC’s privacy policy.
                        </li>
                        <li>
                        Lifebuoy accepts no liability or responsibility to any loss of functionality, technical malfunction or restriction of access in relation to the NFT. Lifebuoy has no control over the AKC and Metaverse sites, which have separate privacy policies, data protection practices and terms and conditions independent of Lifebuoy. The terms and conditions that apply to the MetaMask Wallet are set out on the MetaMask website: <a target="_blank" href="https://consensys.net/terms-of-use/">https://consensys.net/terms-of-use/</a>
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Charity_Page_Items;