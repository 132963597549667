import '../../App.css';
import MerchCartPayment from '../MerchCartPayment';


function Merch() {
    return (
      <>
        
        <MerchCartPayment />
        
      </>
    )
  }
  
  export default Merch;